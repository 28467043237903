<template>
<div>

    <v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <s-toolbar :color="'#244093'" dark label="Reporte Devengado" class="tran"></s-toolbar>
                    <v-row style="margin: auto">
                        <v-card width="100%">

                            <v-row style="margin: auto">
                                <v-col cols="6" lg="2" md="2">
                                    <v-btn width="100%" rounded :color="'info'" small @click="run()">Buscar</v-btn>
                                </v-col>
                                <v-col cols="6" lg="2" md="2">
                                    <v-btn width="100%" rounded :color="'error'" small @click="downloadReport()">Descargar</v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-col>
            </v-row>

            <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
                <v-card color="primary" dark>
                    <v-card-text v-if="messageProcessing.length == 0">
                        Por favor espere
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="text--white pt-4">
                            Error al realizar busqueda <br />
                            {{ messageProcessing }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <v-container>
            <v-tabs v-model="currentItem">
                <v-tab href="#Dashboard"> Dashboard</v-tab>
                <v-tab href="#Abstract"> Resumen</v-tab>
                <v-tab href="#Detail"> Detalle</v-tab>
                
            </v-tabs>

            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'Dashboard'">
                    <clc-report-accrued-dashboard :items="dataDashboard" :objDrilldown="objDrilldown"></clc-report-accrued-dashboard> 
                </v-tab-item>
                <v-tab-item :value="'Detail'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table group-by="SlsQuadrant" :height="itemsDetail.length > 0 ? '350px': 'auto'" :headers="headersDetail" :items="itemsDetail" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 500],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

                <v-tab-item :value="'Abstract'">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-data-table group-by="SlsQuadrant" :height="'auto'" :headers="headersAbstract" :items="itemsAbstract" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 500],
                    }">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-tab-item>

            </v-tabs-items>
        </v-container>
    </v-container>
</div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import * as XLSX from 'xlsx';
import ClcReportAccruedDashboard from './ClcReportAccruedDashboard.vue';

export default {
    components: {ClcReportAccruedDashboard},

    data() {
        return {

            dataDashboard: [],
            objDrilldown: [],
            group: [],

            currentItem: "tab-Funciones",
            processing: false,
            messageProcessing: "",

            itemsDetail: [],
            itemsAbstract: [],
            headersDetail: [{
                    text: "Cuadrante",
                    value: "SlsQuadrant"
                },
                {
                    text: "Cod. venta",
                    value: "SlsSaleCode"
                },
                {
                    text: "Etapa",
                    value: "SlsStage"
                },
                {
                    text: "Lote anterior",
                    value: "SlsPreviousBacth"
                },
                {
                    text: "Lote nuevo",
                    value: "SlsBacthNew"
                },
                {
                    text: "Precio",
                    value: "SlsPrice"
                },
                {
                    text: "Fec. acta",
                    value: "SlsDateRecord"
                },
                {
                    text: "Capital",
                    value: "SlsCapital"
                },
                {
                    text: "Interes",
                    value: "SlsInterest"
                },
                {
                    text: "Cuota",
                    value: "SlsShare"
                },
                {
                    text: "Cant. Cuota",
                    value: "SlsCantShare"
                },

            ],
            headersAbstract: [{
                    text: "Cuadrante",
                    value: "SlsQuadrant"
                },
                {
                    text: "Etapa",
                    value: "SlsStage"
                },

                {
                    text: "Con Acta",
                    value: "SlsWithRecord"
                },
                {
                    text: "Sin Acta",
                    value: "SlsNoRecord"
                },
                {
                    text: "Total",
                    value: "SlsTotal"
                },
            ],
        }

    },

    methods: {

        run() {
            var filter = {}

            this.messageProcessing = ""
            this.processing = true;
            _sClcService.listAccrued(filter, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {

                        this.processing = false;

                        resp.data.detail.forEach(element => {
                            if (element.SlsDateRecord !== null) {
                                element.SlsDateRecord = this.$moment(element.SlsDateRecord).format(this.$const.FormatDateDB)
                            }

                        });

                        this.itemsDetail = resp.data.detail
                        this.itemsAbstract = resp.data.abst


                        var sum = 0, SlsWithRecord = 0, SlsNoRecord = 0,
                                objTmp = {},
                                objTmpdrilldown = {}

                            this.dataDashboard = []
                            this.objDrilldown = []

                            this.group = _.groupBy(this.itemsAbstract.filter(x => x.SlsQuadrant !== 'TOTAL'), "SlsQuadrant");

                            for (let prop in this.group) {
                                    sum = 0
                                    SlsNoRecord = 0
                                    SlsWithRecord = 0

                                    objTmp = {},
                                    objTmpdrilldown = {}

                                    this.group[prop].map((i) => {
                                        sum = sum + i.SlsTotal
                                        SlsWithRecord = SlsWithRecord + i.SlsWithRecord
                                        SlsNoRecord = SlsNoRecord + i.SlsNoRecord

                                    });

                                objTmp.name = prop
                                objTmp.y = sum
                                objTmp.drilldown = prop

                                objTmpdrilldown.id = prop
                                objTmpdrilldown.name = prop
                                objTmpdrilldown.data = []

                                this.dataDashboard.push(objTmp)
                                this.objDrilldown.push(objTmpdrilldown)

                                /* this.group[prop].map((i) => {
                                    

                                }); */
                                objTmpdrilldown.data.push(["Con Acta", SlsWithRecord])
                                objTmpdrilldown.data.push(["Sin Acta", SlsNoRecord])

                            }
                            /* console.log(this.group) */
                            /* console.log(this.dataDashboard)
                            console.log(this.objDrilldown)
 */


                        /* console.log(resp.data) */
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                })
        },

        downloadReport() {
            const workSheet1 = XLSX.utils.json_to_sheet(this.itemsDetail);
            const workSheet2 = XLSX.utils.json_to_sheet(this.itemsAbstract);

            const workBook = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(workBook, workSheet1, "Detalle");
            XLSX.utils.book_append_sheet(workBook, workSheet2, "Resumen");

            //buffer
            let buffer = XLSX.write(workBook, {
                bookType: "xlsx",
                type: "buffer"
            });

            //binary
            XLSX.write(workBook, {
                bookType: "xlsx",
                type: "binary"
            });
            //donwload
            XLSX.writeFile(workBook, "ReporteDevengado.xlsx");

        }

    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
